"use client";

import { storyblokEditable } from "@storyblok/react";
import { useI18n } from "@wojo/localization";
import { Container } from "@wojo/ui";
import cn from "clsx";
import { getSpacingClassName } from "../../client/get-spacing-class-name";
import { useWishlist } from "../../client/use-wishlist";
import { SearchResults } from "../../components/search-results";
import { DealsTourCardGridContent } from "./deals-tour-card-grid-content/DealsTourCardGridContent";
import styles from "./DealsTourCardGrid.module.scss";
import { DealsTourCardGridFallback } from "./DealsTourCardGridFallback";
import { DealsTourCardGridProps } from "./types";
import { NoResultsBanner } from "../../components/search-results/no-results-banner/NoResultsBanner";
import { SchemaScript } from "../../components/schema-script";

const attributesToRetrieve = [
    "activityLevel",
    "assets.kind",
    "assets.image.alt",
    "assets.image.filename",
    "cardImage.alt",
    "cardImage.filename",
    "classLevel",
    "dateRangeLabel",
    "departureStatusBadge",
    "discountAmount",
    "extensionName",
    "hasExtension",
    "id",
    "isOnSale",
    "name",
    "nbCities",
    "nbDays",
    "nbDaysWithExtension",
    "nbRatings",
    "price",
    "rating",
    "salePrice",
    "tourCode",
    "tourLengthRange",
    "tourPagePath",
];

export const DealsTourCardGrid: React.FC<DealsTourCardGridProps> = (props) => {
    const {
        _uid,
        algoliaRuleTriggerContext,
        hiddenFilters,
        numberOfTours,
        showGatewaySelector,
        defaultSort,
        spaceAbove,
        spaceBelow,
        ...rest
    } = props;

    const { getIsInWishlist, toggleWishlist } = useWishlist();
    const dealsTourCardGridGridI18n =
        useI18n("storyblok").storyblok.dealsTourCardGrid;

    const maxDeparturesPerTourCard = 5;
    const filters = (
        ["destinations", "dates", "duration", "price", "activityLevel"] as const
    ).filter((filter) => !hiddenFilters?.includes(filter));
    const hitsPerPage = parseInt(numberOfTours || "18");
    return (
        <Container
            {...storyblokEditable(props)}
            className={cn(
                styles["component-wrapper"],
                getSpacingClassName({ spaceAbove, spaceBelow }),
            )}
        >
            <SearchResults
                queryKey={["deals-tour-card-grid", _uid]}
                queryType="departure"
                distinct={maxDeparturesPerTourCard}
                ruleContexts={
                    algoliaRuleTriggerContext ? [algoliaRuleTriggerContext] : []
                }
                loadingFallback={
                    <DealsTourCardGridFallback
                        numberOfResults={hitsPerPage}
                        hitLabel={dealsTourCardGridGridI18n.hitLabel.l()}
                    />
                }
                hitLabel={dealsTourCardGridGridI18n.hitLabel.l()}
                hitsPerPage={hitsPerPage}
                filters={filters}
                showSort={!hiddenFilters?.includes("sort")}
                defaultSort={defaultSort}
                attributesToRetrieve={attributesToRetrieve}
                showGatewaySelector={showGatewaySelector}
                render={({ hits, hasFiltersApplied }) => {
                    if (!hits.length) {
                        return (
                            <NoResultsBanner
                                hasFiltersApplied={hasFiltersApplied}
                                noResults={dealsTourCardGridGridI18n.noResults}
                            />
                        );
                    }
                    return (
                        <>
                            <SchemaScript
                                data={{
                                    "@context": "https://schema.org",
                                    "@type": "ItemList",
                                    itemListElement: hits.map((hit, index) => ({
                                        "@type": "ListItem",
                                        position: index + 1,
                                        item: {
                                            "@type": "Product",
                                            name: hit.name,
                                            url:
                                                process.env.NEXT_PUBLIC_DOMAIN +
                                                hit.tourPagePath,
                                            image: hit.cardImage?.filename,
                                            description:
                                                hit.overviewDescription,
                                            sku: hit.tourCode,
                                            brand: {
                                                "@type": "Brand",
                                                name: process.env
                                                    .NEXT_PUBLIC_BUSINESS_NAME,
                                            },
                                            ...((hit.salePrice ||
                                                hit.price) && {
                                                offers: {
                                                    "@type": "Offer",
                                                    priceCurrency:
                                                        process.env
                                                            .NEXT_PUBLIC_CURRENCY_CODE,
                                                    price:
                                                        hit.salePrice ||
                                                        hit.price,
                                                    url:
                                                        process.env
                                                            .NEXT_PUBLIC_DOMAIN +
                                                        hit.tourPagePath,
                                                    itemCondition:
                                                        "https://schema.org/NewCondition",
                                                    availability:
                                                        "https://schema.org/InStock",
                                                },
                                            }),
                                            ...(hit.nbRatings > 0 && {
                                                aggregateRating: {
                                                    "@type": "AggregateRating",
                                                    ratingValue: hit.rating,
                                                    reviewCount: hit.nbRatings,
                                                },
                                            }),
                                        },
                                    })),
                                }}
                            />
                            <DealsTourCardGridContent
                                {...rest}
                                departures={hits.map((hit) => {
                                    // There's only one gateway pricing per departure
                                    // Instead of indexing by gateway name we'll just
                                    // grab the only pricing data that's on the hit
                                    const { discountAmount, price, salePrice } =
                                        Object.values(
                                            hit.gatewayPricing ?? {},
                                        )[0] ?? {};

                                    return {
                                        ...hit,
                                        gatewayPricing: {
                                            discountAmount: discountAmount,
                                            price: price,
                                            salePrice: salePrice,
                                        },
                                    };
                                })}
                                getIsInWishlist={getIsInWishlist}
                                showGatewayPricing={!!showGatewaySelector}
                                toggleWishlist={toggleWishlist}
                            />
                        </>
                    );
                }}
            />
        </Container>
    );
};
